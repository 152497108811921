<template>
  <v-dialog v-model="mostrar" persistent width="750">
    <v-card>
      <v-toolbar dense flat color="primary" dark>
        <v-toolbar-title>Solución</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-3">
        <editor-texto
          contenido="examen"
          :idContenido="$route.params.idExamen"
          :texto="solucion"
          ref="editorTexto"
        ></editor-texto>
        <div class="pt-3">
          <v-btn
            :disabled="loading"
            :loading="loading"
            color="primary"
            @click="submit()"
            ><span>Guardar solución</span></v-btn
          >
          <v-btn
            :disabled="loading"
            color="secondary"
            outlined
            class="ml-2"
            @click="$emit('cancelar')"
          >
            <span>Cancelar</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { modificarReactivo } from "./examenReactivos.service";
import editorTexto from "../../contenidos/editorTexto.vue";

export default {
  props: {
    idReactivo: { type: String, required: true },
    idPagina: { type: String, required: true },
    solucion: { type: String, default: "" },
    mostrar: { type: Boolean, default: false },
  },

  components: {
    "editor-texto": editorTexto,
  },

  data: () => ({
    loading: false,
  }),

  methods: {
    async submit() {
      this.loading = true;

      try {
        const { idExamen, idSeccion } = this.$route.params;
        const data = {
          solucion: this.$refs.editorTexto.obtenerTexto(),
        };

        const serverResponse = await modificarReactivo({
          idExamen,
          idSeccion,
          idPagina: this.idPagina,
          idReactivo: this.idReactivo,
          data,
        });
        this.loading = false;

        this.$emit("solucionModificada", serverResponse.reactivo.solucion);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>
